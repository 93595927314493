import React from 'react';
import styled from '@emotion/styled';
import fonts from 'style/fonts';
import { mq } from 'style/variables';

type EnHeadingProps = {
  // NOTE: 使用箇所での命名に変更したい
  size?: 'xsmall' | 'small' | 'middle' | 'large' | 'section' | 'subSection';
};

const sizes = {
  xsmall: { pc: 15, sp: 15 },
  small: { pc: 20, sp: 20 },
  middle: {
    pc: 24,
    sp: 24,
  },
  large: {
    pc: 40,
    sp: 40,
  },
  // 以下はRbrで追加
  section: {
    pc: 32,
    sp: 24,
  },
  subSection: {
    pc: 20,
    sp: 18,
  },
};

const Container = styled.div<EnHeadingProps>`
  font-family: ${fonts.enGothic};
  font-size: ${({ size }) => (size ? sizes[size].pc : 36)}px;
  letter-spacing: 0.95px;
  line-height: 1;
  /* text-transform: uppercase; */
  font-weight: 500 !important;
  ${mq.onlysp} {
    font-size: ${({ size }) => (size ? sizes[size].sp : 36)}px;
    letter-spacing: 0.95px;
  }
`;

export const EnHeading: React.FC<EnHeadingProps> = props => {
  const TagName = Container.withComponent(props.size === 'xsmall' ? 'span' : props.size === 'small' ? 'h4' : 'h2');
  return <TagName {...props}>{props.children}</TagName>;
};
