import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import arrowImageBlack from 'assets/images/icons/icon-arrow-link.svg';
import arrowImageWhite from 'assets/images/icons/icon-arrow-link-white.svg';

type ArrowProps = {
  arrowDir?: 'left' | 'right';
  hoverAnime?: boolean;
  color?: 'black' | 'white';
};

const Container = styled.span<ArrowProps>`
  position: relative;
  right: 1px;
  display: inline-block;
  width: 100%;
  height: 100%;
  ${({ arrowDir }) => {
    if (arrowDir === 'left') {
      return css`
        transform: scale(-1, 1);
      `;
    }
    return '';
  }}
  ${({ color }) => {
    if (color === 'white') {
      return css`
        background: bottom / contain no-repeat url(${arrowImageWhite});
      `;
    }
    return css`
      background: bottom / contain no-repeat url(${arrowImageBlack});
    `;
  }}
`;

export const Arrow: React.FC<ArrowProps> = ({ arrowDir = 'right', color = 'black', ...props }) => {
  return (
    <Container arrowDir={arrowDir} color={color} {...props}>
      {props.children}
    </Container>
  );
};
